import React from "react";
import ReCAPTCHA from "react-google-recaptcha";


class ReCaptcha extends React.Component {
    constructor(props) {
        super(props);
        this.state = { items: [], text: '' };
        // this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    handleOnChange(value) {
        console.log("Captcha value:", value);
    }

    render() {
        return React.createElement(
            "div",
            null,
            React.createElement(
                "h3",
                null,
                // "TODO"
            ),
            React.createElement(TodoList, { items: this.state.items }),
            React.createElement(
                "form",
                { onSubmit: this.handleSubmit },
                React.createElement(
                    "label",
                    // { htmlFor: "new-todo" },
                    // "What needs to be done?"
                ),
                // React.createElement("input", {
                //     id: "new-todo",
                //     onChange: this.handleChange,
                //     value: this.state.text
                // }),
                <ReCAPTCHA
                    sitekey="6LdhSrQjAAAAACfyshnfxYfsTlGd8HE3RTzIlx-3"
                    onChange={this.handleOnChange}
                />,
                // React.createElement(
                //     "button",
                //     null,
                //     "Add #",
                //     this.state.items.length + 1
                // )
            )
        );
    }

    handleChange(e) {
        this.setState({ text: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.text.length === 0) {
            return;
        }
        const newItem = {
            text: this.state.text,
            id: Date.now()
        };
        this.setState(state => ({
            items: state.items.concat(newItem),
            text: ''
        }));
    }
}

class TodoList extends React.Component {
    render() {
        return React.createElement(
            "ul",
            null,
            this.props.items.map(item => React.createElement(
                "li",
                { key: item.id },
                item.text
            ))
        );
    }
}


export default ReCaptcha;
