import React from "react";
import { Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Navbar from './Navbar';
import Home from "./Home";
import Services from "./Services";
import Aboutus from "./Aboutus";
import OurWork from "./OurWork";
import Contact from "./Contact";
import Product from "./Product";
import Project from "./Project";

function App() {
    return (
        <>
            <Navbar />
            <Routes>

                <Route path="/" element={<Home />} />
                <Route path="/Services" element={<Services />} />
                <Route path="/Aboutus" element={<Aboutus />} />
                <Route path="/OurWork" element={<OurWork />} />
                <Route path="/Contact" element={<Contact />} />

            </Routes>

          <Routes>
                <Route path="/Product" element={<Product />} />
                <Route path="/Project" element={<Project />} />

          </Routes>


        </>
    );
};

export default App;
