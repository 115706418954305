import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import Product from './Product';
import Project from "./Project";
import { NavLink } from "react-router-dom";

function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };
  return (
    <Box>
      <Box>
        <Tabs value={tabIndex} onChange={handleTabChange} >
            <NavLink activeClassName="menu_active" className="nav-link active" aria-current="page" to="/Project" style={{ color: "gray", fontWeight: "bold", fontSize: "20px", marginLeft: "auto", marginRight: "auto" }}><center className="prjt"> Project </center></NavLink>
            <NavLink activeClassName="menu_active" className="nav-link active" aria-current="page" to="/Product" style={{ color: "gray", fontWeight: "bold", fontSize: "20px", marginLeft: "auto", marginRight: "auto" }}> <center className="prjt"> Product </center></NavLink>
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {tabIndex === 0 && (
          <Box>
            <Typography>
            </Typography>

          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Typography>


            </Typography>
          </Box>
        )}
        {tabIndex === 2 && (
          <Box>
            <Typography>The third tab</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default App;
