import React from "react";
import pict from '../src/images/pict.png';
import v1acc from "../src/images/V1acc.png";
import the from "../src/images/theinsg.png";
import logoss from "../src/images/Logo-TC-1 1.png";
import white from "../src/images/white logo.png";
import logn from "../src/images/Logo2.png";
import frm from "../src/images/Frame 70.png";
import trm1 from "../src/images/Frame 71.png";
import frmd from "../src/images/Frame 72.png";
import quick from '../src/images/Frame 71 (1).png';
import cool from '../src/images/cool.png';
import phon from '../src/images/Phone.png';
import map from '../src/images/Mappin.png';
import at from '../src/images/At.png';
import logo from '../src/images/logo 1.png';
import inst from '../src/images/InstagramLogo.png';
import Face from '../src/images/FacebookLogo.png';
import linkd from '../src/images/LinkedinLogo.png';
import Footer from './Footer';
import { TextField } from "@mui/material";
import ReCaptcha from './ReCaptcha';
import just from '../src/images/just.png';
import maqapic from '../src/images/maqapic.png';
import inbox from '../src/images/chat-remove.png';
import component from '../src/images/component.png';
import checkbox from '../src/images/checkbox.png';
import cord from '../src/images/code-alt.png';
import subb from '../src/images/picture.png';
import Tabs from './Tabs';


const Project = () => {
    return (    
        <>


            <div className="vitural">
                <img src={pict} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "100%" }} className="img-fluid" />
            </div>


            <div className="container" style={{ textAlign: "center", justifyContent: "center", alignItems: "center", width: "1000px" }}>
                <div className="container" style={{ paddingTop: "50px" }}>
                    <Tabs />

                </div>
                <div className="row" style={{ paddingTop: "80px", textAlign: "center" }}>
                    <div className="col-lg-2">
                        <img src={v1acc} className="img-fluid" />
                    </div>

                    <div className="col-lg-2">
                        <img src={the} className="img-fluid" />
                    </div>

                    <div className="col-lg-2">
                        <img src={logoss} className="img-fluid" />
                    </div>

                    <div className="col-lg-2">
                        <img src={white} className="img-fluid" />
                    </div>

                    <div className="col-lg-2">
                        <img src={logn} className="img-fluid" />
                    </div>

                    <div className="col-lg-2">
                        <img src={just} className="img-fluid" />
                    </div>
                </div>
            </div>


            <div className="container" style={{ width: "1000px" }}>
                <div className="row" style={{ paddingTop: "50px" }}>
                    <div className="col-lg-6">
                        <div className="v1">
                            <h4 style={{ fontWeight: "bold" }}>V1 Accounting</h4>
                            <p style={{ fontSize: "14px" }}>V1 Accounting is the cross-platform application<br /> providing ease for consumers who are facing hurdles<br /> regarding their financial and taxation matters for<br /> themselves and in business too. V1 Accounting provides<br /> two way guides for consumers to access these <br />applications from web app to mobile app. Once the<br /> consumer has purchased the offered service from V1<br /> Accounting. Stakeholder side from V1 Accounting will<br /> allot an expert of that service to the consumer.
                                <br />
                                <br />
                                V1 Accounting is the multi-user cross platform<br /> applications.</p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="v12">
                            <img src={frm} className="img-fluid" />
                        </div>
                    </div>
                </div>


                <div className="row" style={{ paddingTop: "50px" }}>
                    <div className="col-lg-6">
                        <div className="thein">
                            <img src={trm1} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="hub">
                            <h4 style={{ fontWeight: "bold" }}>THe Insight Hub <span>(ICI PVT LTD)</span>  </h4>
                            <p style={{ fontSize: "14px" }}>The Insight Hub is a platform of ICI Pakistan, where they<br /> fill out the forms of surveys through “The Insight Hub App”<br /> and save into their records and the admin will be able to<br /> easily access all the information example like check the<br /> records, edit field, download records and assign the task<br /> to the Tm’s through “The Insight Hub” dashboard.
                                <br />
                                <br />
                                Our aim is to make a friendly UI of this dashboard<br /> because it will make easy to access the records of<br /> surveys and download the recorded form as well; which<br /> will be filled by the Tm’s on the survey.</p>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ paddingTop: "50px" }}>
                    <div className="col-lg-6">
                        <div className="top">
                            <h4 style={{ fontWeight: "bold" }}>Top Canvas</h4>
                            <p style={{ fontSize: "14px" }}>V1 Accounting is the cross-platform application<br /> providing ease for consumers who are facing hurdles<br /> regarding their financial and taxation matters for <br />themselves and in business too. V1 Accounting provides<br /> two way guides for consumers to access these<br /> applications from web app to mobile app. Once the
                                consumer<br /> has purchased the offered service from V1 Accounting.<br /> Stakeholder side from V1 Accounting will <br />allot an expert of that service to the consumer.
                                <br />
                                <br />
                                V1 Accounting is the multi-user cross platform<br /> applications.</p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="topimg">
                            <img src={frmd} className="img-fluid" />
                        </div>
                    </div>
                </div>


                <div className="row" style={{ paddingTop: "50px" }}>
                    <div className="col-lg-6">
                        <div className="qui">
                            <img src={quick} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="quipar">
                            <h4 style={{ fontWeight: "bold" }}>Quiqpro</h4>
                            <p style={{ fontSize: "14px" }}>This system will have multi-data flow criteria which are<br /> divided into 3 active users in a Cross-platform scenario.<br />  Which are:
                                <br />
                                <br />
                                <p style={{ fontWeight: "bold" }}>Web & Mobile apps:</p>
                                Customer.
                                <br />
                                freelancer.
                                <br />
                                <br />
                                <p style={{ fontWeight: "bold" }}>Web portal:</p>
                                Admin </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6 d-flex pt-4">
                        <div className="paragra mt-5">

                            <h6>E-commerce Project</h6>
                            <h4>Maqaami</h4>

                            <br />
                            <br />

                            <p>B to C and B to B online Ecommerce store.</p>
                            <p>Branding and category hierarchical structure.</p>
                            <p>Supply Chain management.</p>
                            <p>Product Trend Analysis.</p>

                        </div>
                    </div>

                    <div className="col-lg-6 pt-4">
                        <div className="subm">
                            <img src={maqapic} style={{ width: "350px" }} className="img-fluid pt-5" />
                        </div>
                    </div>
                </div>

            </div>

            <img src={cool} className="img-fluid" />

            <div className="forntyb">
                <div className="container" style={{ width: "1000px" }}>

                    <div className="row ps-5" style={{ alignItems: "center", marginTop: "-150px" }}>


                        <div className="col-lg-6 ps-5">
                            <div className="managing">

                                <TextField
                                    fullWidth
                                    label="Name"
                                    id="0317258963"
                                />
                            </div>

                            <div className="managing pt-3">

                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    id="0317258963"
                                />
                            </div>

                            <div className="pt-4">

                                <textarea
                                    fullWidth
                                    label="Email Address"
                                    textarea class="form-control"
                                    rows="6"
                                />

                            
                                <br />

                                <div className="btnred">
                                    <button type="submit" class="btn mb-4" style={{ backgroundColor: "#E31E2B", color: "white", border: "none", width: "380px" }}>Send</button>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-6 ps-5">

                            <div className="offi">

                                <div className="ovvc d-flex">

                                    <img src={map} className="img-fluid" style={{ height: "30px" }} />

                                    <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA" className="ps-2"><p style={{ color: "black", textDecorationLine: "none" }}>Office# 006, Mezzanine Floor, Grace <br /> towers,Block-17,Gulistan-e-Jauhar.</p></a>
                                </div>

                                <br />

                                <div className="phom d-flex">

                                    <img src={phon} className="img-fluid" style={{ height: "30px" }} />

                                    <a href="tel:+92-21-34612668 | +92-335-0138248" className="ps-2"><p style={{ color: "black", textDecoration: 'none' }}>+92-21-34612668 | +92-335-0138248</p></a>

                                </div>

                                <br />

                                <div className="hrm d-flex">

                                    <img src={at} className="img-fluid" style={{ height: "30px" }} />

                                    <a href="mailto:info@methologik.com" className="ps-2"><p style={{ color: "black", textDecorationLine: "none" }}>hr@methologik.com</p></a>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </>


    );
};

export default Project;