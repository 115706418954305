import React from "react";
import band from '../src/images/band.png';
import imdy from '../src/images/imdady 1.png';
import tasw from '../src/images/Taswog Logo.png';
import manz from '../src/images/manzil 1.png';
import just from '../src/images/just.png';
import inbox from '../src/images/chat-remove.png';
import component from '../src/images/component.png';
import checkbox from '../src/images/checkbox.png';
import cord from '../src/images/code-alt.png';
import inboxs from '../src/images/chat-remove.png';
import taswag from '../src/images/picture 1.png';
import subb from '../src/images/picture.png';
import manzil from '../src/images/Cover 1.png';
import maqapic from '../src/images/maqapic.png';
import cool from '../src/images/cool.png';
import map from '../src/images/Mappin.png';
import phon from '../src/images/Phone.png';
import at from '../src/images/At.png';
import Footer from "./Footer";
import { flexbox } from "@mui/system";
import { TextField } from "@mui/material";
import Tabs from './Tabs';



const Product = () => {
    return (
        <>


            <div className="prodt">
                <img src={band} style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "100%" }} className="img-fluid" />
            </div>
            <div className="container" style={{ width: "1000px" }}>
                <div className="container" style={{ paddingTop: "50px" }}>
                    <Tabs />
                </div>

                <div className="row" style={{ paddingTop: "80px", textAlign: "center" }}>
                    <div className="col-lg-4">
                        <div className="imdy">
                            <img src={imdy} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="tasw">
                            <img src={tasw} />
                        </div>
                    </div>

                    <div className="col-lg-4">
                        <div className="manz">
                            <img src={manz} />
                        </div>
                    </div>


                    {/* <div className="col-lg-3">
                        <div className="maqam">
                            <img src={just} />
                        </div>
                    </div> */}
                </div>
            </div>


            <div className="container" style={{ width: "1000px" }}>
                <div className="our text-center pt-5">
                    <h3 style={{ fontWeight: "bold" }}>Our Work</h3>
                    <p style={{ fontSize: "14px" }}>We work with and invest in organizations that show synergies with our mission to provide<br /> global blockchain solutions for developers, startups, enterprises, and governments.</p>
                </div>
            </div>


            <div className="container" style={{ width: "1000px" }}>

                <div className="row mt-5">

                    <div className="col-lg-6 col-md-12">

                        <div className="paragra mt-5">

                            <h6>POS AND RMS </h6>

                            <h4>Imdady</h4>

                            <br />

                            <p>Immediate manages your business in the point of sale program<br /> and the restaurant program using next generation technologies</p>

                            <br />

                        </div>

                        <div className="imgpara">

                            <div className="indox d-flex">

                                <img src={inbox} className="img-fluid" style={{ height: "30px" }} />

                                <p style={{ marginLeft: "15px" }}>Inventory Management</p>

                            </div>

                            <div className="indox1 d-flex">

                                <img src={component} className="img-fluid" style={{ height: "30px" }} />

                                <p style={{ marginLeft: "15px" }}>Multi-retailers managment.</p>

                            </div>

                            <div className="indox2 d-flex">

                                <img src={checkbox} className="img-fluid" style={{ height: "30px" }} />

                                <p style={{ marginLeft: "15px" }}>Multiple printing for Different positions</p>

                            </div>

                            <div className="indox3 d-flex">

                                <img src={cord} style={{ height: "30px" }} />

                                <p style={{ marginLeft: "15px" }}>Power of Cloud.</p>

                            </div>

                            <br />
                            <br />

                        </div>

                        <div className="subnhj">
                            <img src={subb} style={{ paddingTop: "250px", width: "500px" }} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">

                        <div className="taswag mt-5">

                            <img src={taswag} style={{ width: "500px" }} className="img-fluid" />

                            <div className="pt-4">

                                <p style={{ paddingTop: "150px" }}>Online Gorcery System  </p>

                                <h4>Taswog</h4>

                                In Taswog customers can browse through the grocery products, add them to the cart, fill in their delivery location details, select their own convenient time slot and then place an order. The company receives these orders, handpicks them, and delivers them to the customer’s household.

                                <br />
                                <br />

                                Taswog scope ended up being really huge and you feel the need to add all kinds of features that would cover all user’s pain points.

                                <br />
                                <br />

                            </div>
                        </div>

                        <div className="ipsum">

                            <div className="lorem">

                                <div className="doller d-flex">

                                    <img src={inbox} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Shop Grocery category wise</p>

                                </div>

                                <div className="doller1 d-flex">

                                    <img src={component} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Delivery guys  live response</p>

                                </div>

                                <div className="doller2 d-flex">

                                    <img src={checkbox} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Location tracking of delivery</p>

                                </div>

                                <div className="doller3 d-flex">

                                    <img src={cord} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Cross platform Data flow</p>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">

                        <div className="manxzil">

                            <p>Society Management System</p>

                            <h4>Manzil</h4>

                            <p>The aim of the project is design contract management <br />solution for multiples community/ properties which is <br />can be used by the renting office management, solution <br />can be branded for on primes or cloud
                            </p>

                            <br />

                            <div className="dummy">

                                <div className="printing d-flex">

                                    <img src={inbox} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Locality management</p>

                                </div>

                                <div className="printing1 d-flex">

                                    <img src={component} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Security Management</p>

                                </div>

                                <div className="printing2 d-flex">

                                    <img src={checkbox} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Maintenance Management</p>

                                </div>

                                <div className="printing3 d-flex">

                                    <img src={cord} className="img-fluid" style={{ height: "30px" }} />

                                    <p style={{ marginLeft: "15px" }}>Accountability of Paid expense.</p>

                                </div>

                            </div>
                        </div>
                    </div>

                    <img src={manzil} style={{ width: "500px", paddingTop: "100px" }} className="img-fluid" />

                </div>
            </div>


            <img src={cool} className="img-fluid" />
            <div className="fornty">
                <div className="container" style={{ width: "1000px" }}>

                    <div className="row ps-5" style={{ alignItems: "center", marginTop: "-400px" }}>

                        <div className="col-lg-6 ps-5">
                            <div className="managing">

                                <TextField
                                    fullWidth
                                    label="Name"
                                    id="0317258963"
                                />
                            </div>

                            <div className="managing pt-3">

                                <TextField
                                    fullWidth
                                    label="Email Address"
                                    id="0317258963"
                                />
                            </div>

                            <div className="pt-4">

                                <textarea
                                    fullWidth
                                    label="Email Address"
                                    textarea class="form-control"
                                    rows="6"
                                />



                                <br />

                                <div className="btnsnd">
                                    <button type="submit" class="btn mb-4" style={{ backgroundColor: "#E31E2B", color: "white", border: "none", width: "380px" }}>Send</button>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-6">

                            <div className="offi ps-5">

                                <div className="ovvc d-flex">

                                    <img src={map} className="img-fluid" style={{ height: "30px" }} />

                                    <h6 className="ps-2">Office# 006, Mezzanine Floor, Grace <br /> towers,Block-17,Gulistan-e-Jauhar.</h6>
                                </div>

                                <br />

                                <div className="phom d-flex">

                                    <img src={phon} className="img-fluid" />

                                    <h6 className="ps-2">+92-21-34612668 | +92-335-0138248</h6>

                                </div>

                                <br />

                                <div className="hrm d-flex">

                                    <img src={at} className="img-fluid" />

                                    <h6 className="ps-2">hr@methologik.com</h6>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />




        </>
    );
};

export default Product;




