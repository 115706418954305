import React from "react";
import Logo from '../src/images/image 3.svg';
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <>

            <div class="navigation-wrap start-header start-style">

                <div className="container" style={{ width: "1100px", margin: "auto" }}>

                    <div className="row">

                        <div className="col-12">

                            <nav className="navbar navbar-expand-lg navbar-light">

                                <div className="container-fluid">

                                    <Link to="/">
                                        <img src={Logo} className="img-fluid" />
                                    </Link>

                                    <NavLink className="navbar-brand" to="/"></NavLink>

                                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                                        <ul class="navbar-nav" style={{ marginLeft: "auto" }}>

                                            <li className="nav-item ps-4" style={{ marginLeft: "170px" }}>
                                                <NavLink activeClassName="menu_active" className="nav-link active" aria-current="page" to="/">Home</NavLink>
                                            </li>

                                            <li className="nav-item ps-4">
                                                <NavLink activeClassName="menu_active" className="nav-link" to="/Aboutus">About us</NavLink>
                                            </li>

                                            {/* <li className="nav-item ps-4">
                                                <NavLink activeClassName="menu_active" className="nav-link" to="/Services">Services</NavLink>
                                            </li> */}
                                            {/* 
                                            <li className="nav-item ps-4">
                                                <NavLink activeClassName="menu_active" className="nav-link" to="/OurWork">Our Work</NavLink>
                                            </li> */}

                                            {/* <li className="nav-item ps-4">
                                            <NavLink activeClassName="menu_active" className="nav-link" to="/Project">Project</NavLink>
                                        </li> */}

                                            <li className="nav-items ps-4">
                                                <NavLink activeClassName="menu_active" className="nav-link" to="/Contact"
                                                    style={{ borderRadius: "10px" }}>Contact</NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};


export default Navbar;

