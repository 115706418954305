import React from "react";
import out from '../src/images/outline12.png';
import vect from '../src/images/Vector.png';
import mobil from '../src/images/smartphone 1.png';
import web from '../src/images/smartphone 1 (1).png';
import game from '../src/images/game.png';
import digital from '../src/images/marketing.png';
import anima from '../src/images/animation.png';
import uiux from '../src/images/design.png';
import mobimg from '../src/images/image4.png';
import img1 from '../src/images/image1.png';
import unmat from '../src/images/unmatched 1.png';
import adob from '../src/images/AdobeStock.png';
import img2 from '../src/images/image2.png';
import img3 from '../src/images/image3.png';
import occut from '../src/images/Occupation.png';
import strem from '../src/images/streamline3.png';
import strm3 from '../src/images/streamline.png';
import Footer from './Footer';

const Services = () => {
    return (

        <>
            <div className="container" style={{ width: "1000px" }}>

                <div className="headimg">

                    <img src={out} className="img-fluid" />

                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="servichead pt-5">
                            <h1 style={{ fontWeight: "bold", paddingTop: "100px" }}>Services Provided</h1>
                            <p>We don’t want to push our ideas on to customers,we simply<br /> want
                                to make what they wants and we believe in the power of recognition.</p>

                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="servimg">
                            <img src={vect} className="img-fluid" />
                        </div>
                    </div>
                </div>

                <div className="row text-center" style={{ paddingTop: "100px" }}>

                    <div className="col-lg-2">

                        <div className="mobrow">

                            <img src={mobil} className="img-fluid" />
                            <h5 style={{ fontSize: "12px" }}>MOBILE APPLICATION</h5>

                        </div>


                    </div>

                    <div className="col-lg-2">
                        <div className="mobrow">
                            <img src={web} className="img-fluid" />
                            <h5 style={{ fontSize: "12px" }}>WEB DEVELOPMENT</h5>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="mobrow">
                            <img src={game} className="img-fluid" />
                            <h5 style={{ fontSize: "12px" }}>GAME DEVELOPMENT</h5>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="mobrow">
                            <img src={digital} className="img-fluid" />
                            <h5 style={{ fontSize: "12px" }}>DIGITAL MARKETING</h5>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="mobrow">
                            <img src={anima} className="img-fluid" />
                            <h5 style={{ fontSize: "12px" }}>ANIMATION</h5>
                        </div>
                    </div>

                    <div className="col-lg-2">
                        <div className="mobrow">
                            <img src={uiux} className="img-fluid" />
                            <h5 style={{ fontSize: "12px" }}>DESIGN & UI/UX</h5>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ paddingTop: "150px" }}>

                    <div className="col-lg-6">
                        <div className="moblapp">
                            <img src={mobil} className="img-fluid" />

                            <h4 style={{ fontSize: "bold" }}>Mobile Application</h4>

                            <p style={{ fontSize: "10px" }} >Methologik develops mobile applications that help<br /> businesses to functions effectively. We hire qualified,<br />
                                and skilled app develpers whose expertise achieves<br /> excellances in andiord, IOS and cross-platform app<br /> development services.
                                <br />

                                <h6 style={{ fontSize: "12px" }}> We Think Mobile First.</h6>
                                We ship rich experiences to the small screens, which <br /> translates into the success of your business.

                                <br />

                                <h6 style={{ fontSize: "12px" }}>Cross Platform Apps</h6>
                                We, at Methologik always thrive to <br />deliver our clients and global businesses a top cross-platform<br /> apps development with a broad arrangement of mobile applications
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">

                        <img src={mobimg} style={{ borderRadius: "10px" }} className="img-fluid" />

                    </div>

                    <div className="col-lg-6">
                        <div className="moblapp">
                            <img src={web} className="img-fluid pt-5" />
                            <h4 style={{ fontSize: "bold" }}>Web development</h4>
                            <p style={{ fontSize: "12px" }} >Methlogik is known as top web development <br />and web desigb company globally delivering the <br />top-noth website development service to craft <br />bespoke web application, websites, web tools, etc., to <br />international businesses as well as startup with 100%<br /> successfull project delivery.

                                <br />
                                <br />
                                . Enterprise Solutions
                                <br />

                                . E-Commerce
                                <br />

                                . Content Management System
                                <br />

                                .  Custom Web Development
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <img src={img1} style={{ marginTop: "50px", borderRadius: "10px" }} className="img-fluid" />

                    </div>


                    <div className="col-lg-6">
                        <div className="moblapp">
                            <img src={game} className="img-fluid pt-5" />
                            <h4 style={{ fontSize: "bold" }}>Game Development</h4>
                            <p style={{ fontSize: "12px" }} >Bring uniqueness and creativity in designing and developing<br /> is our forte. We, at Methologik, always thrive to become a top<br /> game development and design company in the world. At<br /> Methologik, we have an expert and experienced team of game<br /> developers who cater to our clients in the game industry<br /> with satisfactory execution. Our team creates games keeping<br /> in mind the mechanics, rewards, player angagement, and level<br /> design0. Our talented team consists of artists, developers, and <br />testers. Artist creates and designs the asset, the developer<br /> focus on programming while the tester checks that the game <br />is working and expected or not. Our game developers use the<br /> technologies to develop the game like unity, unreal, phaser,<br /> construct senor tower tower, and split metrics.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <img src={unmat} style={{ marginTop: "50px", borderRadius: "10px" }} className="img-fluid" />
                    </div>







                    <div className="col-lg-6">
                        <img src={adob} style={{ marginTop: "50px", borderRadius: "10px" }} className="img-fluid" />
                    </div>

                    <div className="col-lg-6">
                        <div className="moblapp" style={{ marginLeft: "50px" }}>
                            <img src={digital} style={{ marginTop: "50px" }} className="img-fluid" />

                            <h4 style={{ fontSize: "bold" }}>Digital Marketing</h4>
                            <p style={{ fontSize: "12px" }} >We know the importace of the presence of digital marketing <br />in today’s era. Our success in digital marketing helps us to<br /> provide professional digital marketing services to our global<br /> clients as well as startups to give them the best digital exposures.<br /> we strongly believe that digital marketing is the future of marketing<br /> for any business, therefore, we try our best to offer quality services to <br />our clients. We make our clients’ brands present on digital platforms<br /> for their potentail customers, at the right time, and the right platform.
                                <br />
                                <br />
                                <b>.</b>Social Media Marketing
                                <br />
                                <b>.</b>Search Engine Optimization</p>
                        </div>
                    </div>

                    <div className="col-lg-6">

                        <img src={img2} style={{ marginTop: "80px", borderRadius: "10px" }} className="img-fluid" />
                    </div>

                    <div className="col-lg-6">
                        <div className="moblapp" style={{ marginLeft: "50px" }}>
                            <img src={anima} style={{ marginTop: "50px" }} className="img-fluid" />

                            <h4 style={{ fontSize: "bold" }}>Animation</h4>

                            <p style={{ fontSize: "12px" }} >We provide the top level of animation to our clients.<br />
                                We bring you an expert team for animation who transforms<br /> the lifeless images into a live and engaging one. our large pool<br /> of skilled and expert animators perform a complete process of<br /> animation step by step to deliver the best quality of the video.<br /> The process includes video explainer, writing the script and voice <br />over, working on animation, and finally render/publish.
                                <br />
                                <br />
                                <b>.</b>Video Explainer
                                <br />
                                <b>.</b>Writing the script
                                <br />
                                <b>.</b>Working on story board
                                <br />
                                <b>.</b>Illustrations and voice over
                                <br />
                                <b>.</b>Working on animation
                                <br />
                                <b>.</b>Publish</p>
                        </div>
                    </div>

                    <div className="col-lg-6">

                        <img src={img3} style={{ marginTop: "80px", borderRadius: "10px" }} className="img-fluid" />

                    </div>

                    <div className="col-lg-6">
                        <div className="moblapp" style={{ marginLeft: "50px" }}>
                            <img src={uiux} style={{ marginTop: "50px" }} className="img-fluid" />

                            <h4 style={{ fontSize: "bold" }}>Design & UI/UX</h4>

                            <p style={{ fontSize: "12px" }} >Being a leading company, Methologik provides the best service <br />of UI/UX development to our global clients. We develop an <br />eye-catching and attractive design, which will increase the user<br /> interaction and drive sales of our clients. At Methologik, we <br /> have an expert and highly professional team of UI/UX developers<br /> who have years of experience in their field. Our expert team follows<br /> some steps including clients’ and stakeholders’ requirments, compiling <br />user persona, creating a product roadmap, working on low and high <br />fidelity visuals prototypes, design sprints, and testing to build the<br /> best UI/IX designs for our clients.
                                <br />
                                <br />
                                <b>.</b> Design sprints
                                <br />
                                <b>.</b>Clients and stakeholders requirements
                                <br />
                                <b>.</b>Compiling user persona
                                <br />
                                <b>.</b>Creating a product roadmap
                                <br />
                                <b>.</b>Working on high and low fidelity visuals prototypes
                                <br />
                                <b>.</b>Quality Assurance</p>

                        </div>
                    </div>
                </div>
            </div>


            <div className="conthow" style={{ background: "rgba(227, 30, 43, 0.05)" }}>
                <div className="container" style={{ width: "900px" }}>
                    <div className="ehole">
                        <div className="how" style={{ paddingTop: "50px", textAlign: "center" }}>
                            <h2 style={{ fontWeight: "bold" }}>How we work</h2>
                            <p>Step by step process we follow and technology we use</p>
                        </div>

                        <div className="box" style={{ paddingTop: "100px" }}>
                            <div className="whitebox" style={{ background: "#FFFFFF", borderRadius: "10px" }}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img src={occut} className="img-fluid" />
                                    </div>

                                    <div className="col-lg-6">

                                        <div className="vl" style={{ borderLeft: "1px solid gray", height: "50px", marginLeft: "-50px" }}></div>

                                        <h5 style={{ paddingTop: "0px", fontWeight: "bold" }}>01  Ideation & Planning</h5>

                                        <div class="accordion" id="accordionExample" style={{ width: "350px", paddingTop: "40px", marginLeft: "5px" }} >
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <p style={{ color: "#687377" }}>Exploration</p>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingTwo">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <p style={{ color: "#687377" }}>Prototyping</p>                                                </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>






                <div className="contsec" style={{ paddingTop: "50px" }}>
                    <div className="container" style={{ width: "900px" }}>
                        <div className="uprrow" style={{ background: "#FFFFFF", borderRadius: "10px" }}>
                            <div className="deve" style={{ paddingTop: "100px" }}>
                                <div className="row">
                                    <div className="col-lg-6" style={{ marginLeft: "0px" }}>
                                        <h5 style={{ paddingTop: "0px", fontWeight: "bold", marginLeft: "30px" }}>02 Development</h5>


                                        <div class="accordion" id="accordionExample" style={{ width: "350px", paddingTop: "40px", marginLeft: "40px" }} >
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingThree">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <p style={{ color: "#687377" }}>Development</p>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="deveimg">
                                            <img src={strem} className="img-fluid" />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="container" style={{ width: "900px" }}>
                    <div className="howaty" style={{ paddingTop: "50px" }}>
                        <div className="box2" style={{ paddingTop: "10px" }}>
                            <div className="whitbox" style={{ background: "#FFFFFF", borderRadius: "10px" }}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <img src={strm3} className="img-fluid" />
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="vl" style={{ borderLeft: "1px solid gray", height: "50px", marginLeft: "-50px" }}></div>
                                        <h5 style={{ paddingTop: "0px", fontWeight: "bold" }}>03 Releasing</h5>

                                        <div class="accordion" id="accordionExample" style={{ width: "350px", paddingTop: "40px" }} >
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingFour">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        <p style={{ color: "#687377" }}>QA</p>
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingFive">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        <p style={{ color: "#687377" }}>Deployment</p>                                                </button>
                                                </h2>
                                                <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="contsec" style={{ paddingTop: "50px" }}>
                    <div className="container" style={{ width: "900px" }}>
                        <div className="maint" style={{ background: "#FFFFFF", borderRadius: "10px" }}>
                            <div className="whit3" style={{ paddingTop: "100px" }}>
                                <div className="row">
                                    <div className="col-lg-6" style={{ marginLeft: "0px" }}>
                                        <h5 style={{ paddingTop: "0px", fontWeight: "bold", marginLeft: "30px" }}>04 Maintaining</h5>

                                        <div class="accordion" id="accordionExample" style={{ width: "350px", paddingTop: "40px", marginLeft: "30px" }} >
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingSix">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        <p style={{ color: "#687377" }}>QA</p>
                                                    </button>
                                                </h2>
                                                <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingSeven">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        <p style={{ color: "#687377" }}>Deployment</p>                                                </button>
                                                </h2>
                                                <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {/* <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>      </div>

                                    <div className="col-lg-6">
                                        <div className="deveimg">
                                            <img src={strem} className="img-fluid" />
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />


        </>

    );
};

export default Services;
