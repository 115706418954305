import React from "react";
import Footer from './Footer';
import ReCaptcha from './ReCaptcha';
import { TextField } from "@mui/material";



const Contact = () => {
    return (
        <>

            <div className="forntyc mt-5">
                <div className="container" style={{ width: "1100px" }}>

                    <div className="formborde shadow-lg p-3 mb-5 rounded" style={{ paddingRight: "50px", borderRadius: "20px" }}>
                        <h2 style={{ paddingTop: "50px" }}>Connect With Us!</h2>

                        <p style={{ width: "60%" }}>We would love to hear from you. If
                            you have any queries and want to
                            discuss them, then contact us at any
                            time.
                        </p>

                        <div className="aroundbox">
                            <div className="row" style={{ paddingTop: "30px" }}>

                                <div className="col-lg-4">
                                    <div className="managing">

                                        <TextField
                                            fullWidth
                                            label="Name"
                                            id="0317258963"
                                        />
                                    </div>

                                    <div className="managing pt-4">

                                        <TextField
                                            fullWidth
                                            label="Email"
                                            id="0317258963"
                                        />
                                    </div>

                                    <div className="managing pt-4">
                                        <div className="timsle">
                                            <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" style={{ width: "208%" }}>
                                                <option selected>Budget</option>
                                                <option value="1" style={{ fontSize: "16px" }} >$100k+</option>
                                                <option value="2" style={{ fontSize: "16px" }} >$50k - $100k</option>
                                                <option value="3" style={{ fontSize: "16px" }} >$10 - $20k</option>
                                                <option value="3" style={{ fontSize: "16px" }} >Under $10k</option>
                                                <option value="3" style={{ fontSize: "16px" }} >Not Sure</option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className="managing pt-3">
                                        <div className="timsle">
                                            <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" style={{ width: "208%" }}>
                                                <option selected>Timeline</option>
                                                <option value="1" style={{ fontSize: "16px" }} >Yesterday</option>
                                                <option value="2" style={{ fontSize: "16px" }} >With in a Week</option>
                                                <option value="3" style={{ fontSize: "16px" }} >With in a Month</option>
                                                <option value="3" style={{ fontSize: "16px" }} >More Then a Month</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="pt-3">
                                        <div className="timsle">
                                            <textarea
                                                fullWidth style={{ width: "208%" }}
                                                label="Message"
                                                textarea class="form-control"
                                                value="Message"
                                                rows="4"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="compho">
                                        <div className="managing">

                                            <TextField
                                                fullWidth
                                                label="Company name"
                                                id="0317258963"
                                            />
                                        </div>
                                    </div>

                                    <div className="managing pt-4">

                                        <TextField
                                            fullWidth
                                            label="Phone number"
                                            id="0317258963"
                                        />
                                    </div>
                                </div>

                                <div className="col-lg-4">

                                </div>

                            </div>
                        </div>

                        <div className="aroundbox2">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sch">
                                        <div className="schedule shadow-lg p-3 mb-5 bg-body rounded">
                                            <h2 className="pt-2">Schedule Meeting</h2>
                                            <a href="https://calendly.com/methologik"><button type="submit" class="btn mb-4" style={{ backgroundColor: "#E31E2B", color: "white", border: "none", width: "150px" }}>Schedule</button></a>
                                            <p>Schedule an initial FREE 30 MEETING Consulting call to discuss your needs.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="capy">
                            <ReCaptcha />
                        </div>

                        <br />

                        <div className="btnpri">
                            <a href="#"><button type="submit" class="btn mb-4" style={{ backgroundColor: "#E31E2B", color: "white", border: "none", width: "150px" }}>Send</button></a>
                        </div>

                    </div>
                </div>
            </div>

            <div className="foo">
                <Footer />
            </div>

            {/* <div className="col-lg-6 ps-5">

                            <div className="offi">

                                <div className="ovvc d-flex">

                                    <img src={map} className="img-fluid" style={{ height: "30px" }} />

                                    <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA" className="ps-2"><p style={{ color: "black", textDecorationLine: "none" }}>Office# 006, Mezzanine Floor, Grace towers, <br />Block 17, Gulistan-e-Jauhar, 75290, Karachi.</p></a>
                                </div>

                                <br />

                                <div className="phom d-flex">

                                    <img src={phon} className="img-fluid" style={{ height: "30px" }} />

                                    <a href="tel: +92-21-34612668 | +92-335-0138248" className="ps-2"><p style={{ color: "black", textDecoration: 'none' }}> +92-21-34612668 | +92-335-0138248</p></a>

                                </div>

                                <br />

                                <div className="hrm d-flex">

                                    <img src={at} className="img-fluid" style={{ height: "30px" }} />

                                    <a href="mailto:info@methologik.com" className="ps-2"><p style={{ color: "black", textDecorationLine: "none" }}>hr@methologik.com</p></a>

                                </div>

                            </div>
                        </div> */}


        </>

    );
};

export default Contact;