import React from "react";
import imagess from '../src/images/ici.png';
import virtual from '../src/images/V1accF.png';
import imagesss from '../src/images/Imdadynew.png';
import imagessss from '../src/images/tabba-logo 1.png';
import imagessssss from '../src/images/Noble-Cornerf.png';
import imagesssssss from '../src/images/future-gates 1.png';
import picture from '../src/images/Group_88.png';
import pictu from '../src/images/Group_89.png';
import pict from '../src/images/Group_90.png';
import pichgy from '../src/images/Group_91.png';
import tasvir from '../src/images/Group_92.png';
import inbox from '../src/images/chat-remove.png';
import firstimgimdady from '../src/images/Imdady.png';
import secondimgtaswog from '../src/images/TaswogFinal.png';
import thirdimgmanzil from '../src/images/ManzilF1.png';
import component from '../src/images/component.png';
import checkbox from '../src/images/checkbox.png';
import cord from '../src/images/code-alt.png';
import tree from '../src/images/Tree.svg';
import man from '../src/images/Man.svg';
import women from '../src/images/Women.svg';
import Video from "../src/images/Project-cycleFFF.mp4";
import Footer from "./Footer";

const Home = () => {
    return (
        <>


            <div className="container mt-5" style={{ width: "1100px" }}>

                <div className="row">
                    <div className="col-lg-7 d-flex align-items-center">
                        <div className="head mt-5" id="text" style={{ width: "90%" }}>

                            <h1>Your Technology Partner</h1>

                            <p className="text-justify">Methologik delivers software consultancy in the tech sector to international businesses
                                and local clients with 100% successful project delivery. We hire the best innovators who
                                can deliver successful projects to our clients with our design-focused approach and
                                project execution tactics.</p>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="headerim">
                            <img src={tree} style={{ position: "absolute" }} className="img-fluide animate__animated animate__zoomIn" id="tree" />
                            <img src={man} style={{ paddingTop: "128px", marginLeft: "65px" }} className="img-fluid animate__animated animate__fadeInLeft" id="manvect" />
                            <img src={women} style={{ paddingTop: "130px", marginLeft: "260px" }} className="img-fluid animate__animated animate__fadeInRight" id="womanvect" />

                        </div>
                    </div>
                </div>

            </div>

            <div className="container" style={{ width: "1100px" }}>
                <div className="trus">
                    <h4>Trusted By</h4>
                </div>

                <div className="row d-flex text-center" style={{ paddingTop: "50px" }}>

                    <div className="col-lg-2 col-md-12 padding">
                        <div className="icih">
                            <img src={imagess} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-12 padding">
                        <div className="icih">
                            <img src={imagessss} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-12 padding">
                        <div className="icih">
                            <img src={imagesss} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-12 padding">
                        <div className="icih">
                            <img src={imagesssssss} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-12 padding">
                        <div className="icih">
                            <img src={imagessssss} className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-12 padding">
                        <div className="icih">
                            <img src={virtual} className="img-fluid" />
                        </div>
                    </div>

                </div>


                <div className="centhead">
                    <h1>Services</h1>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-4 col-md-12 padding">
                        <div className="cards">
                            <img src={picture} className="img-fluid" />
                            <h5 className="mt-3">Consultancy</h5>
                            <p className="text-justify mt-3">With the professional guidance of our qualified consultants,
                                you can overcome the technological constraints that are getting in the way of
                                your success. We help clients with designs, development with custom
                                functionality, and maintain software applications that meet their specific needs.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 padding">
                        <div className="cards">
                            <img src={pictu} className="img-fluid" />
                            <h5 className="mt-3">Software Development</h5>
                            <p className="mt-3">Innovating the future with softwares! We develop
                                software with custom functionality to meet your business needs. The software
                                development process involves a detailed analysis of the user’s requirements,
                                designing the software architecture, coding, and testing.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 padding">
                        <div className="cards">
                            <img src={pict} className="img-fluid" />
                            <h5 className="mt-3">Design</h5>
                            <p className="mt-3">Unlocking the creative potential! We design UI that delivers on
                                users’ needs, giving them a good experience. We believe in the power of design,
                                which are visually appealing, with a clear and consistent structure that follows
                                the natural workflow of the user.</p>
                        </div>
                    </div>
                </div>

                <div className="row pt-5 justify-content-center">
                    <div className="col-lg-4 col-md-12 padding">
                        <div className="cards">
                            <img src={pichgy} className="img-fluid" />
                            <h5 className="mt-3">Mobile Application Development</h5>
                            <p className="mt-3">We develop engaging and impressive
                                mobile applications that help businesses to function effectively. In mobile app
                                development we provide services of iOS Apps, Android App, Hybrid App, and
                                Cross Platform Apps.</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 padding">
                        <div className="cards">
                            <img src={tasvir} className="img-fluid" />
                            <h5 className="mt-3">Website Development</h5>
                            <p className="mt-3">Transforming your ideas into revolutionary web
                                solutions that benefit both the end-user and the business itself. We develop
                                custom web solutions that resonate with functionality and UI. Our website
                                service includes but not limited to, custom website development, e-commerce,
                                and many others.</p>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-12">
                        <div className="cards">
                            <img src={tasvires} className="img-fluid" />
                            <h5 className="mt-3">DESIGN & UI/UX</h5>
                            <p className="mt-3">Methologik follow the process includes research, ideation, user flow design, prototype creation, complete web/ mobile solutions. In such a way we aim to transform the digital experience to more human and deliver solutions that are meaningful and technically viable.   </p>
                        </div>
                    </div> */}

                </div>
            </div>

            {/* <div className="errobox">
                <div className="contheady">
                    <div className="container" style={{ width: "1100px" }}>
                        <div className="row mt-5">
                            <div className="col-lg-6 animate__animated animate__bounceInLeft">
                                <div className="soft">
                                    <h2>The Software design life <br /> cycle at Methologik</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="vectorsarrow d-flex">
                                    <div className="vectorsim1">
                                        <img src={vector1} className="img-fluid" id="vectorstyle1" />
                                    </div>
                                    <div className="vectorsim2">
                                        <img src={vector2} className="img-fluid" id="vectorstyle2" />
                                    </div>
                                    <div className="vectorsim3">
                                        <img src={vector3} className="img-fluid" id="vectorstyle3" />
                                    </div>
                                    <div className="vectorsim4">
                                        <img src={vector4} className="img-fluid" id="vectorstyle4" />
                                    </div>
                                    <div className="vectorsim5">
                                        <img src={vector5} className="img-fluid" id="vectorstyle5" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="container" style={{ width: "1100px", paddingTop: "80px" }}>
                <div className="projectcyle">
                    <video controls autostart autoPlay src={Video} type="myVideo" />
                    <video muted autoPlay loop>
                        <source src={Video} />
                    </video>

                </div>
            </div> */}


            <div className="container text-center mt-5" style={{ width: "1100px" }}>
                <div className="ourhead">
                    <h1>Our Work</h1>
                </div>
            </div>


            <div className="container" style={{ width: "1100px" }}>
                <div className="row" style={{ paddingTop: "100px" }}>

                    <div className="col-lg-6 col-md-12">
                        <div className="paragra" style={{ width: "90%" }}>

                            <h6 className="pt-3">POS AND RMS</h6>
                            <h4 className="pt-1">Imdady</h4>
                            <p className="text-justify pt-1">Immediate manages your business in the point of sale program and the restaurant program using next generation technologies</p>

                        </div>

                        <div className="imgpara pt-2">

                            <div className="indox d-flex">
                                <img src={inbox} className="img-fluid" style={{ height: "30px" }} />
                                <p style={{ marginLeft: "15px" }}>Inventory Management</p>
                            </div>

                            <div className="indox1 d-flex">
                                <img src={component} className="img-fluid" style={{ height: "30px" }} />
                                <p style={{ marginLeft: "15px" }}>Multi-retailers managment.</p>
                            </div>

                            <div className="indox2 d-flex">
                                <img src={checkbox} className="img-fluid" style={{ height: "30px" }} />
                                <p style={{ marginLeft: "15px" }}>Multiple printing for Different positions</p>
                            </div>

                            <div className="indox3 d-flex">
                                <img src={cord} style={{ height: "30px" }} />
                                <p style={{ marginLeft: "15px" }}>Power of Cloud.</p>

                            </div>
                            <div className="btn mt-3">
                                <a href="#"><button>Learn more</button></a>
                            </div>

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="subnhj animate__animated animate__fadeInUp">
                            <img src={firstimgimdady} className="img-fluid" />

                        </div>
                    </div>
                </div>




                <div className="container pt-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="taswag animate__animated animate__zoomIn">
                                <img src={secondimgtaswog} className="img-fluid" />
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="pt-0 text-justify onlineheading" id="text" style={{ width: "90%" }}>
                                <p className="pt-5">Online Gorcery System  </p>
                                <h4>Taswog</h4>
                                <p className="text-justify"> In Taswog customers can browse through the grocery products, add them to the cart, fill in their delivery location details, select their
                                    own convenient time slot and then place an order. The company receives these orders, handpicks them, and delivers them to the customer’s household.</p>

                                {/* <p> Taswog scope ended up being really huge and you feel the need to add all kinds of features that would cover all user’s pain points.</p> */}
                            </div>

                            <div className="ipsum">
                                <div className="lorem">

                                    <div className="doller d-flex">
                                        <img src={inbox} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Shop Grocery category wise</p>
                                    </div>

                                    <div className="doller1 d-flex">
                                        <img src={component} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Delivery guys  live response</p>
                                    </div>

                                    <div className="doller2 d-flex">
                                        <img src={checkbox} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Location tracking of delivery</p>
                                    </div>

                                    <div className="doller3 d-flex">
                                        <img src={cord} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Cross platform Data flow</p>
                                    </div>

                                    <div className="btn">
                                        <a href="#"><button>Learn more</button></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container pt-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">

                            <div className="manxzil text-justify" id="text" style={{ width: "90%" }}>
                                <p>Society Management System</p>
                                <h4>Manzil</h4>
                                <p className="pt-2 text-justify">The aim of the project is design contract management solution for multiples community/ properties
                                    which is can be used by the renting office management, solution can be branded for on primes or cloud
                                </p>

                                <div className="dummy pt-2">

                                    <div className="printing d-flex">
                                        <img src={inbox} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Locality management</p>
                                    </div>

                                    <div className="printing1 d-flex">
                                        <img src={component} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Security Management</p>
                                    </div>

                                    <div className="printing2 d-flex">
                                        <img src={checkbox} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Maintenance Management</p>
                                    </div>

                                    <div className="printing3 d-flex">
                                        <img src={cord} className="img-fluid" style={{ height: "30px" }} />
                                        <p style={{ marginLeft: "15px" }}>Accountability of Paid expense.</p>
                                    </div>

                                    <div className=" btn mt-3">
                                        <a href="#"><button>Learn more</button></a>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 animate__animated animate__fadeInUp">
                            <img src={thirdimgmanzil} style={{ paddingTop: "100px" }} className="img-fluid" />
                        </div>
                    </div>
                </div>

            </div>


            <div className="footr" style={{ marginTop: "50px" }}>
                <Footer />
            </div>



        </>

    );
};


export default Home;


