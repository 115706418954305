import React from "react";
import pict from '../src/images/pict.png';
import Tabs from './Tabs';
import map from '../src/images/Mappin.png';
import phon from '../src/images/Phone.png';
import at from '../src/images/At.png';
import logo from '../src/images/logo 1.png';
import inst from '../src/images/InstagramLogo.png';
import Face from '../src/images/FacebookLogo.png';
import linkd from '../src/images/LinkedinLogo.png';
import Footer from './Footer';
import ReCaptcha from './ReCaptcha';
import { TextField } from "@mui/material";



const OurWork = () => {
    return (
        <>

            <div className="our">
                <img src={pict} style={{display:"block",marginLeft:"auto",marginRight:"auto",width:"100%"}} className="img-fluid" />
            </div>

            <div className="container" style={{ paddingTop: "50px", width: "1000px"}}>
                <Tabs />
            </div>

            <br />
            <br />
            <hr />

            <div className="container" style={{ width: "1000px" }}>

                <div className="row" style={{ alignItems: "center", paddingTop: "50px" }}>

                    <div className="col-lg-6 ps-5">
                        <div className="managing">

                            <TextField
                                fullWidth
                                label="Name"
                                id="0317258963"
                            />
                        </div>

                        <div className="managing pt-3">

                            <TextField
                                fullWidth
                                label="Email Address"
                                id="0317258963"
                            />
                        </div>

                        <div className="pt-4">

                            <textarea
                                fullWidth
                                label="Email Address"
                                textarea class="form-control"
                                rows="6"
                            />

                            <div className="capy">
                                <ReCaptcha />
                            </div>

                            <br />

                            <div className="btnpri">
                                <button type="submit" class="btn mb-4 text-center" style={{ backgroundColor: "#E31E2B", color: "white", border: "none", width: "380px" }}>Send</button>
                            </div>
                        </div>
                    </div>




                    <div className="col-lg-6 ps-5">

                        <div className="offi">

                            <div className="ovvc d-flex">

                                <img src={map} className="img-fluid" style={{ height: "30px" }} />

                                <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA" className="ps-3" ><p style={{ color: "black", textDecorationLine: "none" }}>Office# 006, Mezzanine Floor, Grace <br /> towers,Block-17,Gulistan-e-Jauhar.</p></a>
                            </div>

                            <br />

                            <div className="phom d-flex">

                                <img src={phon} className="img-fluid" style={{ height: "30px" }} />

                                <a href="tel: +92-21-34612668 | +92-335-0138248" className="ps-3"><p style={{ color: "black", textDecoration: 'none' }}> +92-21-34612668 | +92-335-0138248</p></a>

                            </div>

                            <br />

                            <div className="hrm d-flex">

                                <img src={at} className="img-fluid" style={{ height: "30px" }} />

                                <a href="mailto:info@methologik.com" className="ps-3"><p style={{ color: "black", textDecorationLine: "none" }}>hr@methologik.com</p></a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />


        </>

    );
};



export default OurWork;