import React from "react";
import vectabiut from '../src/images/tABLE.png';
import grounp from '../src/images/Group_85.png';
import rest from '../src/images/Success.png';
import what from '../src/images/Business-Growth.png';
import mapp from '../src/images/Sourcef.png';
import bulding from '../src/images/skyline.png';
import Footer from './Footer';



const Aboutus = () => {

    return (
        <>

            <div className="container mt-5" style={{ width: "1100px" }}>

                <div className="row">
                    <div className="col-lg-7 d-flex align-items-center">
                        <div className="abou" style={{ width: "90%",paddingTop:"100px" }}>

                            <h1>About us</h1>

                            <p className="text-justify mt-3">With a superior approach to software development and a solution-focused strategy, we are
                                committed to serving corporate and large-scale clients with the technical consultancy they need.
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <div className="imgabo">
                            <img src={vectabiut} style={{ position: "absolute" }} className="img-fluide animate__animated animate__fadeInRight" id="vectabiut" />
                            <img src={grounp} style={{ marginLeft: "130px", paddingTop: "118px" }} className="img-fluid animate__animated animate__fadeInLeft" id="grounp" />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="weuselogics">
                <div className="container" style={{ width: "1100px", paddingTop: "280px", }}>
                    <div className="we text-center animate__animated animate__zoomIn">
                        <h3 style={{ color: "#E31E2B" }}>"We Use Logics And Techniques To Bring Innovation In Work."</h3>
                    </div>
                </div>
            </div> */}

            <div className="container" style={{ width: "1100px", paddingTop: "150px" }}>
                <div className="row" style={{ paddingTop: "100px" }}>

                    <div className="col-lg-6">

                        <div className="who text-justify" style={{ width: "90%" }}>
                            <h5 style={{ fontWeight: "bold" }}>WHO WE ARE</h5>
                            <p style={{ fontSize: "15px" }}>We provide experiences that help business to breathe with success!</p>

                            <p style={{ fontSize: "14px" }} className="text-justify">We are a premier software house with a diverse team of experts in software engineering,
                                development, and design. Our goal is to bring innovative, high-quality software solutions to our
                                clients. With highly qualified and enthusiastic team, we work with different technologies, platforms,
                                and programming languages to provide tailored solutions. We are passionate about creating userfriendly, secure, and reliable software that will enable our clients to stay ahead of the competition.</p>

                            <p style={{ fontSize: "14px" }} className="text-justify">Our team works in an agile manner and can engage well with our clients across different stages of
                                their development. We have been working in the industry for more than a decade and have won
                                multiple international clients. We deal in Website Development, Mobile App Development, Custom
                                Software Development, Ecommerce Solutions, UI/UX, and Digital Media services.</p>

                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="whoimg">
                            <img src={rest} style={{ borderRadius: "10px" }} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="container" style={{ width: "1100px" }}>
                <div className="row" style={{ paddingTop: "100px" }}>

                    <div className="col-lg-6">
                        <div className="whoimg">
                            <img src={what} style={{ borderRadius: "10px"}} className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-6">

                        <div className="who ps-2 pt-2" style={{ width: "90%" }}>
                            <h5 style={{ fontWeight: "bold" }}>What We Do</h5>
                            <p style={{ fontSize: "15px" }}>We serve with Industry 4.0 solutions that accelerate business growth!</p>

                            <p style={{ fontSize: "14px" }} className="text-justify">We code solutions for our clients and invest in research and development to ensure to stay on top of
                                the latest industry trends and technologies. Not only do we bring cutting-edge technology to our
                                client, but we also get in depth about its implication and potential benefits. Our team of industry experienced professionals can work with you to deploy business solutions, from launch through
                                implementation to exploitation.</p>

                            <p style={{ fontSize: "14px" }} className="text-justify">We focus on creating strong customer base, providing excellent customer service, and cultivating
                                relationships with industry partners and vendors. Along with creating strong customer base, we also
                                focus on marketing clients’ services and products to potential market and works on creating strong
                                digital footprint with an active website and engaging social media accounts. </p>

                        </div>
                    </div>

                </div>
            </div>


            <div className="back" style={{ backgroundColor: "#F8FDFF", marginTop: "50px" }}>
                <div className="container" style={{ width: "1100px" }}>
                    <div className="wewor" style={{ paddingTop: "50px" }}>
                        <h4 style={{ fontWeight: "bold" }} className="text-center">We Work Here</h4>
                    </div>

                    <div className="row pt-5">
                        <div className="col-5">
                            <div className="mapim">
                                {/* <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA"></a> */}
                                <div className="pakisbox shadow-lg p-3 mb-5 rounded">
                                    <img src={bulding} className="img-fluid pt-2" />
                                    <h5 className="pt-3" style={{ fontWeight: "bold" }}>Pakistan Office Location</h5>
                                    <p>Mezzanine floor, Grace Tower, Block 17 Gulistan-e-Johar, Karachi, Karachi City, Sindh 75290</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-7">
                            <div className="mapim">
                                {/* <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA"></a> */}
                                <img src={mapp} className="img-fluid" />
                            </div>
                            {/* <div className="amer">
                                <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA"><img src={amerloc} className="img-fluid" /></a>
                            </div>
                            <div className="pakloc">
                                <a href="https://goo.gl/maps/LqBxfS8oMKZvQiSAA"><img src={amerloc} className="img-fluid" /></a>
                            </div> */}
                        </div>

                    </div>

                    {/* <div class="cu-maps-main">
                        <div class="map-img">
                        <img src={ourmap} className="img-fluid" />
                        </div>
                        <div class="marker-abs">
                        <img src={amerloc} className="img-fluid" />
                                <div class="cu-au-address">
                                    <p>7322 Southwest Fwy Suite #1-1132, Houston, TX 77036, USA</p>
                                </div>
                        </div>
                        <div class="marker-abs2">
                        <img src={pakloc} className="img-fluid" />
                                <div class="cu-au-address2">
                                    <p>75j Al-Falah Rd, P.E.C.H.S Block 2 Block 2 PECHS, Karachi, Karachi City, Sindh 75400</p>
                                </div>
                        </div>
                    </div> */}










                </div>
                <div className="" style={{ paddingTop: "100px" }}></div>
            </div>



            <Footer />

        </>

    );
};


export default Aboutus;